import type { Theme } from "@mui/material";
import {
  Button,
  ButtonBase,
  Checkbox,
  Divider,
  FormControlLabel,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { memo, useEffect } from "react";

import { Step } from "../enums/step";
import { useLoadGuide } from "../hooks/useLoadGuide";
import { useLoadScan } from "../hooks/useLoadScan";
import { useSetObjectVisibilityByStep } from "../hooks/useSetObjectVisibilityByStep";
import type { AppState } from "../store/AppState";
import { useAppState } from "../store/AppState";
import { communicateSuccessToDesktopApp } from "../utils/communication";
import { getVisibleSteps } from "../utils/steps";

const stepComponents = {
  [Step.REVIEW_VIEW_DIRECTION]: {
    label: "Review View Direction",
    testId: "sidebar-step-1-button",
    render: (currentStep, setCurrentStep, stepNumber) => (
      <SidebarItem
        key={stepNumber}
        active={String(currentStep === Step.REVIEW_VIEW_DIRECTION)}
        onClick={() => setCurrentStep(Step.REVIEW_VIEW_DIRECTION)}
        data-testid="sidebar-step-1-button"
      >
        <Badge active={String(currentStep === Step.REVIEW_VIEW_DIRECTION)}>
          <span>{stepNumber}</span>
        </Badge>
        <Typography variant="body2" sx={{ flex: 1 }}>
          Review View Direction
        </Typography>
      </SidebarItem>
    ),
  },
  [Step.SET_GUIDE_PARAMETERS]: {
    label: "Set Guide Parameters",
    testId: "sidebar-step-3-button",
    render: (
      currentStep,
      setCurrentStep,
      stepNumber,
      wallThickness,
      guideOffset,
      setGuideParameters,
      isInspectionWindowsEnabled,
      setIsInspectionWindowsEnabled
    ) => (
      <SidebarItem
        key={stepNumber}
        active={String(currentStep === Step.SET_GUIDE_PARAMETERS)}
        onClick={() => setCurrentStep(Step.SET_GUIDE_PARAMETERS)}
        data-testid="sidebar-step-3-button"
      >
        <Stack>
          <Stack
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            <Badge active={String(currentStep === Step.SET_GUIDE_PARAMETERS)}>
              <span>{stepNumber}</span>
            </Badge>
            <Typography variant="body2" sx={{ flex: 1 }}>
              Set Guide Parameters
            </Typography>
          </Stack>
          {currentStep === Step.SET_GUIDE_PARAMETERS ? (
            <Stack>
              <TextField
                required
                id="wallThickness"
                label="Wall thickness"
                placeholder="Wall thickness value"
                variant="outlined"
                type="number"
                value={wallThickness}
                onChange={(e) =>
                  setGuideParameters({
                    wallThickness: Number(e.target.value),
                    guideOffset,
                  })
                }
                margin="normal"
                data-testid="wall-thickness-input"
              />
              <TextField
                required
                id="guideOffset"
                label="Offset"
                placeholder="Guide offset value"
                variant="outlined"
                type="number"
                value={guideOffset}
                onChange={(e) =>
                  setGuideParameters({
                    wallThickness,
                    guideOffset: Number(e.target.value),
                  })
                }
                margin="normal"
                data-testid="guide-offset-input"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isInspectionWindowsEnabled}
                    onChange={(e) =>
                      setIsInspectionWindowsEnabled(e.target.checked)
                    }
                    inputProps={{ "aria-label": "Enable inspection windows" }}
                    data-testid="guide-inspection-window-checkbox"
                  />
                }
                label="Enable inspection windows"
              />
            </Stack>
          ) : null}
        </Stack>
      </SidebarItem>
    ),
  },
  [Step.REVIEW_CONTACT_POINTS]: {
    label: "Review Contact Points",
    testId: "sidebar-step-2-button",
    render: (currentStep, setCurrentStep, stepNumber) => (
      <SidebarItem
        key={stepNumber}
        active={String(currentStep === Step.REVIEW_CONTACT_POINTS)}
        onClick={() => setCurrentStep(Step.REVIEW_CONTACT_POINTS)}
        data-testid="sidebar-step-2-button"
      >
        <Badge active={String(currentStep === Step.REVIEW_CONTACT_POINTS)}>
          <span>{stepNumber}</span>
        </Badge>
        <Typography variant="body2" sx={{ flex: 1 }}>
          Review Contact Points
        </Typography>
      </SidebarItem>
    ),
  },
  [Step.GENERATE_GUIDE]: {
    label: "Generate Guide",
    testId: "sidebar-step-4-button",
    render: (currentStep, setCurrentStep, stepNumber) => (
      <SidebarItem
        key={stepNumber}
        active={String(currentStep === Step.GENERATE_GUIDE)}
        onClick={() => setCurrentStep(Step.GENERATE_GUIDE)}
        data-testid="sidebar-step-4-button"
      >
        <Badge active={String(currentStep === Step.GENERATE_GUIDE)}>
          <span>{stepNumber}</span>
        </Badge>
        <Typography variant="body2" sx={{ flex: 1 }}>
          Generate Guide
        </Typography>
      </SidebarItem>
    ),
  },
  [Step.REVIEW_INSPECTION_WINDOWS]: {
    label: "Review Inspection Windows",
    testId: "sidebar-step-5-button",
    render: (currentStep, setCurrentStep, stepNumber) => (
      <SidebarItem
        key={stepNumber}
        active={String(currentStep === Step.REVIEW_INSPECTION_WINDOWS)}
        onClick={() => setCurrentStep(Step.REVIEW_INSPECTION_WINDOWS)}
        data-testid="sidebar-step-5-button"
      >
        <Badge active={String(currentStep === Step.REVIEW_INSPECTION_WINDOWS)}>
          <span>{stepNumber}</span>
        </Badge>
        <Typography variant="body2" sx={{ flex: 1 }}>
          Review Inspection Windows
        </Typography>
      </SidebarItem>
    ),
  },
  [Step.APPROVE_GUIDE]: {
    label: "Approve Guide",
    testId: "sidebar-step-6-button",
    render: (currentStep, setCurrentStep, stepNumber) => (
      <SidebarItem
        key={stepNumber}
        active={String(currentStep === Step.APPROVE_GUIDE)}
        onClick={() => setCurrentStep(Step.APPROVE_GUIDE)}
        data-testid="sidebar-step-6-button"
      >
        <Badge active={String(currentStep === Step.APPROVE_GUIDE)}>
          <span>{stepNumber}</span>
        </Badge>
        <Typography variant="body2" sx={{ flex: 1 }}>
          Approve Guide
        </Typography>
      </SidebarItem>
    ),
  },
};

export const Sidebar = memo(({ taskId }: { taskId: string }) => {
  useLoadScan(taskId);
  useLoadGuide(taskId);
  useSetObjectVisibilityByStep();
  const isViewerLoading = useAppState((s: AppState) => s.viewerIsLoading);
  const isScanLoaded = useAppState((s: AppState) => s.isScanLoaded);
  const isNextButtonEnabled = useAppState(
    (s: AppState) => s.isNextButtonEnabled
  );
  const setIsNextButtonEnabled = useAppState(
    (s: AppState) => s.setIsNextButtonEnabled
  );

  const currentStep = useAppState((s: AppState) => s.currentStep);
  const setCurrentStep = useAppState((s: AppState) => s.setCurrentStep);
  const wallThickness = useAppState(
    (s: AppState) => s.guideParameters.wallThickness
  );
  const guideOffset = useAppState(
    (s: AppState) => s.guideParameters.guideOffset
  );
  const setGuideParameters = useAppState((s: AppState) => s.setGuideParameters);
  const isInspectionWindowsEnabled = useAppState(
    (s) => s.isInspectionWindowsEnabled
  );
  const setIsInspectionWindowsEnabled = useAppState(
    (s) => s.setIsInspectionWindowsEnabled
  );

  useEffect(() => {
    if (!isScanLoaded) {
      setCurrentStep(Step.REVIEW_VIEW_DIRECTION);
    }

    if (currentStep === Step.SET_GUIDE_PARAMETERS) {
      setIsNextButtonEnabled(true);
    }
  }, [currentStep, isScanLoaded]);

  const visibleSteps = getVisibleSteps(isInspectionWindowsEnabled);

  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: 0,
        borderRight: (t: Theme) => `1px solid ${t.palette.divider}`,
        boxSizing: "border-box",
        width: 380,
        p: 1,
        px: 0,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        paddingBottom: 4,
      }}
    >
      <Stack gap={2} padding={2} marginBottom={2}>
        {visibleSteps.map((step, index) =>
          stepComponents[step].render(
            currentStep,
            setCurrentStep,
            index + 1,
            wallThickness,
            guideOffset,
            setGuideParameters,
            isInspectionWindowsEnabled,
            setIsInspectionWindowsEnabled
          )
        )}
      </Stack>
      <Divider />
      <Stack
        flexDirection="row"
        justifyContent="flex-end"
        gap={4}
        paddingTop={4}
        paddingRight={4}
        marginTop={2}
      >
        <Button
          color="primary"
          variant="contained"
          disabled={
            currentStep === Step.REVIEW_VIEW_DIRECTION || isViewerLoading
          }
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          Previous
        </Button>
        {currentStep !== Step.APPROVE_GUIDE ? (
          <Button
            color="primary"
            variant="contained"
            disabled={!isNextButtonEnabled || isViewerLoading}
            onClick={() => {
              setIsNextButtonEnabled(false);
              setCurrentStep(currentStep + 1);
            }}
            data-testid="sidebar-next-button"
          >
            Next
          </Button>
        ) : null}
        {currentStep === Step.APPROVE_GUIDE ? (
          <Button
            color="primary"
            disabled={isViewerLoading}
            variant="contained"
            onClick={communicateSuccessToDesktopApp}
            data-testid="sidebar-approve-button"
          >
            Approve
          </Button>
        ) : null}
      </Stack>
    </Paper>
  );
});

const Badge = styled("div")<{ active?: string }>(({ theme, active }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor:
    active === "true"
      ? theme.palette.primary.main
      : theme.palette.background.paper,
  color:
    active === "true"
      ? theme.palette.primary.contrastText
      : theme.palette.text.primary,
  boxSizing: "border-box",
  border: active === "true" ? "none" : `1px solid ${theme.palette.divider}`,
}));

const SidebarItem = styled(ButtonBase)<{ active?: string }>(
  ({ theme, active }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "left",
    cursor: "pointer",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,

    "&:hover": {
      background: theme.palette.action.hover,
    },
    "&:disabled": {
      opacity: 0.5,
    },

    "& *": {
      fontWeight: active === "true" ? 600 : 400,
    },
  })
);
