/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Guide Design
 * OpenAPI spec version: 0.1.0
 */
import {
  useMutation
} from '@tanstack/react-query'
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  CreateGuideCreateGuideTaskIdPostParams,
  HTTPValidationError,
  TaskParams
} from '../model'




/**
 * Endpoint that receives a task ID and task parameters to create a new guide design based on the
input. It performs several operations such as syncing the related `task_id` files from S3, reading the guide
data from a JSON, creating objects representing different parts of the guide, and generating the final STL file
for the guide.

Args: task_id (str): The ID of the current task. task_params (TaskParams): Parameters used to generate the guide.
call_cdx_guide_service (bool): Flag to enable the creation of the guide calling the external cDXGuideService. Is
False by default.

Returns:
    FileResponse: A file response containing the generated STL file.
 * @summary Endpoint responsible for creating the guide
 */
export const createGuideCreateGuideTaskIdPost = (
    taskId: string,
    taskParams: TaskParams,
    params?: CreateGuideCreateGuideTaskIdPostParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.post(
      `/create_guide/${taskId}`,
      taskParams,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }



export const getCreateGuideCreateGuideTaskIdPostMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createGuideCreateGuideTaskIdPost>>, TError,{taskId: string;data: TaskParams;params?: CreateGuideCreateGuideTaskIdPostParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof createGuideCreateGuideTaskIdPost>>, TError,{taskId: string;data: TaskParams;params?: CreateGuideCreateGuideTaskIdPostParams}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createGuideCreateGuideTaskIdPost>>, {taskId: string;data: TaskParams;params?: CreateGuideCreateGuideTaskIdPostParams}> = (props) => {
          const {taskId,data,params} = props ?? {};

          return  createGuideCreateGuideTaskIdPost(taskId,data,params,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateGuideCreateGuideTaskIdPostMutationResult = NonNullable<Awaited<ReturnType<typeof createGuideCreateGuideTaskIdPost>>>
    export type CreateGuideCreateGuideTaskIdPostMutationBody = TaskParams
    export type CreateGuideCreateGuideTaskIdPostMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Endpoint responsible for creating the guide
 */
export const useCreateGuideCreateGuideTaskIdPost = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createGuideCreateGuideTaskIdPost>>, TError,{taskId: string;data: TaskParams;params?: CreateGuideCreateGuideTaskIdPostParams}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof createGuideCreateGuideTaskIdPost>>,
        TError,
        {taskId: string;data: TaskParams;params?: CreateGuideCreateGuideTaskIdPostParams},
        TContext
      > => {

      const mutationOptions = getCreateGuideCreateGuideTaskIdPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    