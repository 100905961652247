import { guide } from "@promaton/api-client";
import type { ViewerObject, ViewerObjectMap } from "@promaton/scan-viewer";
import { useObjects } from "@promaton/scan-viewer";
import { useEffect } from "react";
import { useAsync } from "react-use";

import { Step } from "../enums/step";
import { useAppState } from "../store/AppState";
import { getAPIOptions } from "../utils/api";
import { generateRandomGuideColorWithSeed } from "../utils/color";
import { captureAndReportAxiosError } from "../utils/error";

export const useLoadGuide = (taskId?: string) => {
  const guideParams = useAppState((s) => s.guideParams);
  const objects = useObjects((s) => s.objects);
  const setObjects = useObjects((s) => s.setObjects);
  const deleteObject = useObjects((s) => s.deleteObject);
  const currentStep = useAppState((s) => s.currentStep);
  const setIsViewerLoading = useAppState((s) => s.setViewerIsLoading);
  const setCurrentStep = useAppState((s) => s.setCurrentStep);
  const guideParameters = useAppState((s) => s.guideParameters);
  const isInspectionWindowsEnabled = useAppState(
    (s) => s.isInspectionWindowsEnabled
  );
  const sleeveParameters = useAppState((s) => s.sleeveParameters);
  const setCurrentLoadingProgress = useAppState(
    (s) => s.setCurrentLoadingProgress
  );
  const implantFDI = useAppState((s) => s.implantFDI);
  const errors = useAppState((s) => s.errors);
  const setErrors = useAppState((s) => s.setErrors);
  const guideColor = useAppState((s) => s.guideColor);
  const setGuideColor = useAppState((s) => s.setGuideColor);
  const apiKey = useAppState((s) => s.apiKey);

  const fetchedGuide = useAsync(async () => {
    try {
      if (
        currentStep !== Step.GENERATE_GUIDE ||
        !taskId ||
        !guideParams?.contact_points_params ||
        !sleeveParameters ||
        !guideParameters
      )
        return;

      setCurrentLoadingProgress(() => 0);
      setIsViewerLoading(true);
      const interval = setInterval(() => {
        setCurrentLoadingProgress((currentProgress) => {
          const newProgress = currentProgress + 1;
          if (newProgress > 98) {
            clearInterval(interval);
            return 98;
          }
          return newProgress;
        });
      }, 700);
      const options = getAPIOptions(setCurrentLoadingProgress, apiKey, {
        responseType: "arraybuffer",
      });

      const response = await guide.createGuide
        .createGuideCreateGuideTaskIdPost(
          taskId,
          {
            sleeve: sleeveParameters,
            guide: {
              wall_thickness: guideParameters.wallThickness,
              offset: guideParameters.guideOffset,
            },
            insert_direction: guideParams?.insert_direction,
            horizontal_direction: guideParams?.horizontal_direction,
            implant_fdi: implantFDI.toString(),
            contact_points_params: guideParams?.contact_points_params,
            ...(isInspectionWindowsEnabled && {
              inspection_windows_params: guideParams?.inspection_windows_params,
            }),
          },
          {
            call_cdx_guide_service: true,
          },

          options
        )
        .finally(() => {
          clearInterval(interval);
          setCurrentLoadingProgress(() => 99);
        });

      const arrayBuffer: ArrayBuffer = response?.data as ArrayBuffer;
      const file = new Blob([new Uint8Array(arrayBuffer)], {
        type: "application/octet-stream",
      });

      const newGuideColor = generateRandomGuideColorWithSeed(taskId);

      if (!guideColor) {
        setGuideColor(newGuideColor);
      }

      const viewerObject: ViewerObject = response && {
        url: URL.createObjectURL(file),
        group: "Guide",
        objectType: "stl",
        color: guideColor ?? newGuideColor,
        clipToPlanes: true,
        opacity: 0.85,
        metalness: 0,
        renderOrder: 1,
        crossSectionAlongCurve: true,
      };

      const id = `guide_${Date.now()}`;

      const viewerObjectMap = {
        [id]: viewerObject,
      } as ViewerObjectMap;

      viewerObject && setObjects(viewerObjectMap, true);
      setIsViewerLoading(false);
      setCurrentStep(
        isInspectionWindowsEnabled
          ? Step.REVIEW_INSPECTION_WINDOWS
          : Step.APPROVE_GUIDE
      );

      return viewerObject;
    } catch (err) {
      await captureAndReportAxiosError(
        err,
        errors,
        setErrors,
        "Error creating guide"
      );
    }
  }, [
    taskId,
    guideParams?.contact_points_params,
    currentStep,
    sleeveParameters,
    guideParameters,
    implantFDI,
    apiKey,
  ]);

  useEffect(() => {
    if (fetchedGuide?.value || !fetchedGuide?.loading) return;

    const guideObjs = Object.entries(objects).filter(
      ([_id, object]) => object?.group === "Guide"
    );

    guideObjs.forEach(([id, _obj]) => {
      deleteObject(id);
    });
  }, [fetchedGuide]);

  return fetchedGuide;
};
