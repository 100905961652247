import { Stack, styled, Typography, useTheme } from "@mui/material";
import { PerspectiveView, Viewer, Viewport } from "@promaton/scan-viewer";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-use";

import { ContactPoints } from "./components/ContactPoints";
import { InspectionWindows } from "./components/InspectionWindows";
import { OverlayLoader } from "./components/OverlayLoader";
import OverlayRibbon from "./components/OverlayRibbon";
import { Sidebar } from "./components/Sidebar";
import { useAppState } from "./store/AppState";

const AppContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100vh",
  backgroundColor: theme.palette.background.default,
}));

const ViewContainer = styled("div")({
  flex: 1,
  height: "100vh",
  position: "relative",
});

function App() {
  const theme = useTheme();
  const location = useLocation();
  const apiKey = useAppState((s) => s.apiKey);
  const setApiKey = useAppState((s) => s.setApiKey);

  const queryParams = new URLSearchParams(location.search);
  const taskId = useMemo(
    () => location?.pathname?.split("/").pop(),
    [location]
  );

  const authToken = queryParams.get("auth") ?? apiKey;

  useEffect(() => {
    if (authToken) setApiKey(authToken);
  }, [authToken]);

  if (!authToken) {
    return (
      <Stack justifyContent="center" alignItems="center" height={512} gap={4}>
        <Typography variant="h6">
          Access Denied - Please Contact the Administrator
        </Typography>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
          width={64}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636"
          />
        </svg>
      </Stack>
    );
  }

  return (
    <AppContainer>
      <Stack flexDirection={"row"}>
        <Sidebar taskId={taskId} />
        <ViewContainer>
          <OverlayLoader taskId={taskId} />
          <Viewer
            theme={theme}
            sx={{ height: "100%" }}
            config={{
              enableSelectionToolWindow: false,
              enableTransforms: false,
              enableObjectEditing: false,
            }}
            sceneExtension={
              <>
                {taskId ? <ContactPoints taskId={taskId} /> : null}
                {taskId ? <InspectionWindows taskId={taskId} /> : null}
              </>
            }
          >
            <Stack sx={{ flexDirection: "row", flex: 1, height: "100%" }}>
              <Viewport
                name="Main view"
                component={PerspectiveView}
                allowOrientationSwitch
                props={{
                  isSecondaryView: false,
                }}
              />
            </Stack>
          </Viewer>
        </ViewContainer>
        <OverlayRibbon />
      </Stack>
    </AppContainer>
  );
}

export default App;
