export const GuideColors = [
  "#3770d2",
  "#3cb43c",
  "#f8b42c",
  "#58468f",
  "#e15759",
];

const seededRandom = (seed: number) => {
  const x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
};

const hashString = (s: string) =>
  s.split("").reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);

export const generateRandomGuideColorWithSeed = (taskId: string) => {
  const seed = hashString(taskId);

  return GuideColors[Math.floor(seededRandom(seed) * GuideColors.length)];
};
