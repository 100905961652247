import { Step } from "../enums/step";

export const getVisibleSteps = (
  isInspectionWindowsEnabled: boolean
): Step[] => {
  const conditions = {
    [Step.REVIEW_INSPECTION_WINDOWS]: isInspectionWindowsEnabled,
  };

  const allSteps = [
    Step.REVIEW_VIEW_DIRECTION,
    Step.SET_GUIDE_PARAMETERS,
    Step.REVIEW_CONTACT_POINTS,
    Step.GENERATE_GUIDE,
    Step.REVIEW_INSPECTION_WINDOWS,
    Step.APPROVE_GUIDE,
  ];

  return allSteps.filter((step) => conditions[step] !== false);
};
