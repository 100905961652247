import { ThemeProvider } from "@mui/material";
import { lightTheme } from "@promaton/theme";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import { initializeSentry } from "./utils/sentry";

initializeSentry();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider theme={lightTheme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
