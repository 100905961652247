import { Sphere } from "@react-three/drei";
import React, { useEffect } from "react";
import { useAsync } from "react-use";
import { Vector3 } from "three";

import { Step } from "../enums/step";
import { useRecenterByStep } from "../hooks/useRecenterByStep";
import { useAppState } from "../store/AppState";
import { requestGuideParamsFromAPI } from "../utils/api";
import { captureAndReportAxiosError } from "../utils/error";

const p = new Vector3();

interface ContactPointsProps {
  taskId: string;
}

export const ContactPoints = ({ taskId }: ContactPointsProps) => {
  useRecenterByStep();
  const guideParams = useAppState((s) => s.guideParams);
  const setGuideParams = useAppState((s) => s.setGuideParams);
  const currentStep = useAppState((s) => s.currentStep);
  const setIsViewerLoading = useAppState((s) => s.setViewerIsLoading);
  const setIsNextButtonEnabled = useAppState((s) => s.setIsNextButtonEnabled);
  const setCurrentLoadingProgress = useAppState(
    (s) => s.setCurrentLoadingProgress
  );
  const isInspectionWindowsEnabled = useAppState(
    (s) => s.isInspectionWindowsEnabled
  );
  const errors = useAppState((s) => s.errors);
  const setErrors = useAppState((s) => s.setErrors);
  const apiKey = useAppState((s) => s.apiKey);

  useEffect(() => {
    if (
      currentStep === Step.REVIEW_CONTACT_POINTS &&
      guideParams &&
      guideParams.contact_points_params.length > 0
    ) {
      setIsNextButtonEnabled(true);
    }
  }, [guideParams, currentStep]);

  useAsync(async () => {
    try {
      if (!taskId || guideParams || currentStep !== Step.REVIEW_CONTACT_POINTS)
        return;

      setIsViewerLoading(true);
      setCurrentLoadingProgress(() => 0);

      const guideParamData = await requestGuideParamsFromAPI(
        taskId,
        isInspectionWindowsEnabled,
        apiKey,
        setCurrentLoadingProgress
      );

      setGuideParams(guideParamData);
      setIsNextButtonEnabled(true);
      setIsViewerLoading(false);
      return guideParamData;
    } catch (err) {
      await captureAndReportAxiosError(
        err,
        errors,
        setErrors,
        "Error generating contact points"
      );
    }
  }, [taskId, currentStep, apiKey]);

  if (
    !guideParams ||
    (currentStep !== Step.REVIEW_CONTACT_POINTS &&
      currentStep !== Step.SET_GUIDE_PARAMETERS)
  )
    return null;

  return guideParams.contact_points_params.map((point, index) => {
    const pC = p.set(point.position_x, point.position_y, point.position_z);

    return (
      <group
        name="contact_points"
        key={`cp-${index}-${point.position_x + point.position_y + point.position_z}`}
      >
        <Sphere
          args={[point.diameter / 2, 16, 16]}
          position={[pC.x, pC.z, -pC.y]}
        >
          <meshStandardMaterial
            color="firebrick"
            transparent
            opacity={
              currentStep === Step.REVIEW_CONTACT_POINTS ||
              currentStep === Step.SET_GUIDE_PARAMETERS
                ? 0.5
                : 0
            }
          />
        </Sphere>
        <Sphere
          key={`cp-${point.position_x + point.position_y + point.position_z}`}
          args={[1, 8, 8]}
          position={[pC.x, pC.z, -pC.y]}
        >
          <meshStandardMaterial color="yellow" />
        </Sphere>
      </group>
    );
  });
};
