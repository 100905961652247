import { guide } from "@promaton/api-client";
import type { AxiosProgressEvent } from "axios";

let lastProgress = 0;

export const getAPIOptions = (
  setCurrentLoadingProgress: (
    updateFunction: (currentState: number) => number
  ) => void,
  apiToken: string | undefined,
  extraProps?: unknown
) => {
  if (!apiToken || !import.meta.env.VITE_API_BASE_URL) {
    throw new Error("API key or base URL not set");
  }

  return {
    headers: {
      Authorization: apiToken,
      "Access-Control-Allow-Origin": "*",
      "X-Requested-With": "XMLHttpRequest",
      "Keep-Alive": "timeout=120, max=1000",
      Connection: "Keep-Alive",
    },
    onDownloadProgress: function (progressEvent: AxiosProgressEvent) {
      if (!progressEvent.total) return;
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );

      if (percentCompleted > lastProgress) {
        setCurrentLoadingProgress(() => percentCompleted);
        lastProgress = percentCompleted;
      }
    },
    baseURL: import.meta.env.VITE_API_BASE_URL,
    ...(typeof extraProps === "object" && extraProps !== null
      ? extraProps
      : {}),
  };
};

export const requestGuideParamsFromAPI = async (
  taskId: string,
  isInspectionWindowsEnabled: boolean,
  apiKey: string,
  setCurrentLoadingProgress: (
    updateFunction: (currentState: number) => number
  ) => void
) => {
  const interval = setInterval(() => {
    setCurrentLoadingProgress((currentProgress) => {
      const newProgress = currentProgress + 1;
      if (newProgress > 98) {
        clearInterval(interval);
        return 98;
      }
      return newProgress;
    });
  }, 100);

  const options = getAPIOptions(setCurrentLoadingProgress, apiKey);
  const response = await guide.createGuideParams
    .createGuideParamsCreateGuideParamsTaskIdGet(
      `${taskId}`,
      {
        enable_inspection_windows: isInspectionWindowsEnabled,
      },
      options
    )
    .finally(() => {
      clearInterval(interval);
      setCurrentLoadingProgress(() => 99);
    });
  return response.data as GuideParams;
};
