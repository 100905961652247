import { Environment, getEnvironment } from "./environment";

export async function initializeSentry() {
  const environment = getEnvironment();

  if (
    environment === Environment.DEV ||
    environment === Environment.ACCEPTANCE ||
    environment === Environment.PROD
  ) {
    const Sentry = await import("@sentry/react");
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      environment: environment,
      tracesSampleRate: 1.0,
      tracePropagationTargets: [
        "localhost",
        "https://guidedesign.dev.promaton.ai",
        "https://guidedesign-acceptance.dev.promaton.ai",
      ],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
}
