import { useObjects } from "@promaton/scan-viewer";
import { useEffect, useMemo } from "react";

import { Step } from "../enums/step";
import { useAppState } from "../store/AppState";

export const useSetObjectVisibilityByStep = () => {
  const objects = useObjects((s) => s.objects);
  const setObject = useObjects((s) => s.setObject);
  const currentStep = useAppState((s) => s.currentStep);
  const guideObj = useMemo(
    () =>
      Object.entries(objects).find(
        ([_id, object]) => object?.group === "Guide"
      ),
    [currentStep]
  );

  useEffect(() => {
    if (!guideObj) return;
    const [id, object] = guideObj;

    const opacity =
      currentStep === Step.APPROVE_GUIDE ||
      currentStep === Step.GENERATE_GUIDE ||
      currentStep === Step.REVIEW_INSPECTION_WINDOWS
        ? 0.85
        : 0;

    id &&
      object &&
      setObject(id, {
        ...object,
        opacity,
      });
  }, [guideObj, currentStep]);
};
