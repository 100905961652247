export enum Environment {
  DEV = "DEV",
  ACCEPTANCE = "ACCEPTANCE",
  PROD = "PROD",
  LOCALDEV = "LOCALDEV",
}

export function getEnvironment(): Environment {
  const currentUrl = window.location.href;

  if (currentUrl.includes("guidedesign.dev")) {
    return Environment.DEV;
  }

  if (currentUrl.includes("guidedesign-acceptance.dev")) {
    return Environment.ACCEPTANCE;
  }

  if (import.meta.env.DEV) {
    return Environment.LOCALDEV;
  }

  return Environment.PROD;
}
