/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Guide Design
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  CreateGuideParamsCreateGuideParamsTaskIdGetParams,
  HTTPValidationError
} from '../model'




/**
 * @summary Endpoint responsible for creating the contact points, insert direction and inspection windows
 */
export const createGuideParamsCreateGuideParamsTaskIdGet = (
    taskId: string,
    params?: CreateGuideParamsCreateGuideParamsTaskIdGetParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.get(
      `/create_guide_params/${taskId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getCreateGuideParamsCreateGuideParamsTaskIdGetQueryKey = (taskId: string,
    params?: CreateGuideParamsCreateGuideParamsTaskIdGetParams,) => {
    return [`/create_guide_params/${taskId}`, ...(params ? [params]: [])] as const;
    }

    
export const getCreateGuideParamsCreateGuideParamsTaskIdGetQueryOptions = <TData = Awaited<ReturnType<typeof createGuideParamsCreateGuideParamsTaskIdGet>>, TError = AxiosError<HTTPValidationError>>(taskId: string,
    params?: CreateGuideParamsCreateGuideParamsTaskIdGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof createGuideParamsCreateGuideParamsTaskIdGet>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCreateGuideParamsCreateGuideParamsTaskIdGetQueryKey(taskId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof createGuideParamsCreateGuideParamsTaskIdGet>>> = ({ signal }) => createGuideParamsCreateGuideParamsTaskIdGet(taskId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof createGuideParamsCreateGuideParamsTaskIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type CreateGuideParamsCreateGuideParamsTaskIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof createGuideParamsCreateGuideParamsTaskIdGet>>>
export type CreateGuideParamsCreateGuideParamsTaskIdGetQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Endpoint responsible for creating the contact points, insert direction and inspection windows
 */
export const useCreateGuideParamsCreateGuideParamsTaskIdGet = <TData = Awaited<ReturnType<typeof createGuideParamsCreateGuideParamsTaskIdGet>>, TError = AxiosError<HTTPValidationError>>(
 taskId: string,
    params?: CreateGuideParamsCreateGuideParamsTaskIdGetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof createGuideParamsCreateGuideParamsTaskIdGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCreateGuideParamsCreateGuideParamsTaskIdGetQueryOptions(taskId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



