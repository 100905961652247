import { Circle, Cylinder, Line } from "@react-three/drei";
import React, { useCallback, useMemo, useRef } from "react";
import type { Mesh } from "three";
import {
  BufferGeometry,
  DoubleSide,
  Euler,
  Float32BufferAttribute,
  MeshBasicMaterial,
  Vector3,
} from "three";

import { Step } from "../enums/step";
import { getPositionCorrectionForInspectionWindow } from "../utils/3d";

const p = new Vector3();
const INSPECTION_WINDOW_OPACITY = 0.5;
const INSPECTION_WINDOW_OUTLINE_COLOR = "black";
const INSPECTION_WINDOW_OUTLINE_WIDTH = 1;
const CROSSHAIR_DOT_COLOR = "lime";
const CROSSHAIR_LINE_COLOR = "black";
const CROSSHAIR_RADIUS = 0.5;
const CYLINDER_SEGMENTS = 16;
const RENDER_ORDER_FRONT = 99999999999;
const RENDER_ORDER_BACK = 999;

interface InspectionWindowProps {
  point: InspectionWindow;
  index: number;
  currentStep: number;
}

export const InspectionWindow = ({
  point,
  index,
  currentStep,
}: InspectionWindowProps) => {
  const cylinderRef = useRef<Mesh>(null);

  const generateCircumferenceGeometry = useCallback(
    (radius: number, segments: number, yOffset: number) => {
      const angleStep = (Math.PI * 2) / segments;
      const vertices: number[] = [];

      Array.from({ length: segments }).forEach((_, i) => {
        const angle = i * angleStep;
        const nextAngle = (i + 1) * angleStep;
        const x = radius * Math.cos(angle);
        const z = radius * Math.sin(angle);
        const nextX = radius * Math.cos(nextAngle);
        const nextZ = radius * Math.sin(nextAngle);
        vertices.push(x, yOffset, z, nextX, yOffset, nextZ);
      });

      const geometry = new BufferGeometry();
      geometry.setAttribute(
        "position",
        new Float32BufferAttribute(vertices, 3)
      );

      return geometry;
    },
    []
  );

  const generateVerticalEdgesGeometry = useCallback(
    (angle: number, diameter: number, height: number) => {
      const radius = diameter / 2;
      const x = radius * Math.cos(angle);
      const z = radius * Math.sin(angle);
      const vertices = [x, -height / 2, z, x, height / 2, z];
      const geometry = new BufferGeometry();
      geometry.setAttribute(
        "position",
        new Float32BufferAttribute(vertices, 3)
      );
      return geometry;
    },
    []
  );

  const generateCrosshairGeometry = useCallback(
    (axis: "X" | "Y", diameter: number) => {
      const radius = CROSSHAIR_RADIUS;
      const size = diameter / 4;
      let vertices;

      if (axis === "X") {
        vertices = [
          -radius,
          0,
          0,
          -radius - size,
          0,
          0,
          radius,
          0,
          0,
          radius + size,
          0,
          0,
        ];
      } else {
        vertices = [
          0,
          -radius,
          0,
          0,
          -radius - size,
          0,
          0,
          radius,
          0,
          0,
          radius + size,
          0,
        ];
      }

      const geometry = new BufferGeometry();
      geometry.setAttribute(
        "position",
        new Float32BufferAttribute(vertices, 3)
      );
      return geometry;
    },
    []
  );

  const pC = useMemo(
    () => p.set(point.position_x, point.position_y, point.position_z),
    [point.position_x, point.position_y, point.position_z]
  );
  const position = useMemo(
    () => getPositionCorrectionForInspectionWindow(point),
    [point]
  );
  const bottomElementsPosition = useMemo(
    () => new Vector3(pC.x, pC.z, -pC.y),
    [pC]
  );
  const bottomElementsRotation = useMemo(
    () => new Euler(-Math.PI / 2, 0, 0),
    []
  );

  const cylinderArgs: [number, number, number, number, number] = useMemo(
    () => [
      point.diameter / 2,
      point.diameter / 2,
      point.height,
      CYLINDER_SEGMENTS,
      CYLINDER_SEGMENTS,
    ],
    [point.diameter, point.height]
  );

  const topCircumferenceGeometry = useMemo(() => {
    return generateCircumferenceGeometry(
      point.diameter / 2,
      CYLINDER_SEGMENTS,
      point.height / 2
    );
  }, [point.diameter]);

  const bottomCircumferenceGeometry = useMemo(() => {
    return generateCircumferenceGeometry(
      point.diameter / 2,
      CYLINDER_SEGMENTS,
      -point.height / 2
    );
  }, [point.diameter]);

  const verticalEdgesGeometryA = useMemo(() => {
    return generateVerticalEdgesGeometry(0, point.diameter, point.height);
  }, [point.diameter, point.height]);

  const verticalEdgesGeometryB = useMemo(() => {
    return generateVerticalEdgesGeometry(Math.PI, point.diameter, point.height);
  }, [point.diameter, point.height]);

  const crosshairGeometryX = useMemo(() => {
    return generateCrosshairGeometry("X", point.diameter);
  }, [point.diameter]);

  const crosshairGeometryY = useMemo(() => {
    return generateCrosshairGeometry("Y", point.diameter);
  }, [point.diameter]);

  if (!cylinderArgs) return null;
  return (
    <>
      <Cylinder
        key={`cp-${index}-${point.position_x + point.position_y + point.position_z}`}
        args={cylinderArgs}
        renderOrder={RENDER_ORDER_FRONT}
        position={position}
        ref={cylinderRef}
      >
        <meshBasicMaterial
          color={INSPECTION_WINDOW_OUTLINE_COLOR}
          transparent
          opacity={
            currentStep === Step.REVIEW_INSPECTION_WINDOWS
              ? INSPECTION_WINDOW_OPACITY
              : 0
          }
        />
      </Cylinder>

      <Line
        points={Array.from(
          topCircumferenceGeometry.getAttribute("position").array
        )}
        position={position}
        renderOrder={RENDER_ORDER_FRONT}
        color={INSPECTION_WINDOW_OUTLINE_COLOR}
        polygonOffset
        polygonOffsetFactor={-RENDER_ORDER_FRONT}
        polygonOffsetUnits={-RENDER_ORDER_FRONT}
        lineWidth={INSPECTION_WINDOW_OUTLINE_WIDTH}
      />
      <Line
        points={Array.from(
          bottomCircumferenceGeometry.getAttribute("position").array
        )}
        position={position}
        renderOrder={RENDER_ORDER_FRONT}
        color={INSPECTION_WINDOW_OUTLINE_COLOR}
        polygonOffset
        polygonOffsetFactor={-RENDER_ORDER_FRONT}
        polygonOffsetUnits={-RENDER_ORDER_FRONT}
        lineWidth={INSPECTION_WINDOW_OUTLINE_WIDTH}
      />
      <Line
        points={Array.from(
          verticalEdgesGeometryA.getAttribute("position").array
        )}
        position={position}
        renderOrder={RENDER_ORDER_FRONT}
        color={INSPECTION_WINDOW_OUTLINE_COLOR}
        polygonOffset
        polygonOffsetFactor={-RENDER_ORDER_FRONT}
        polygonOffsetUnits={-RENDER_ORDER_FRONT}
        lineWidth={INSPECTION_WINDOW_OUTLINE_WIDTH}
      />
      <Line
        points={Array.from(
          verticalEdgesGeometryB.getAttribute("position").array
        )}
        position={position}
        renderOrder={RENDER_ORDER_FRONT}
        color={INSPECTION_WINDOW_OUTLINE_COLOR}
        polygonOffset
        polygonOffsetFactor={-RENDER_ORDER_FRONT}
        polygonOffsetUnits={-RENDER_ORDER_FRONT}
        lineWidth={INSPECTION_WINDOW_OUTLINE_WIDTH}
      />
      <Circle
        args={[CROSSHAIR_RADIUS, CYLINDER_SEGMENTS, CYLINDER_SEGMENTS]}
        rotation={bottomElementsRotation}
        position={bottomElementsPosition}
        renderOrder={-RENDER_ORDER_FRONT}
        material={
          new MeshBasicMaterial({
            color: CROSSHAIR_DOT_COLOR,
            side: DoubleSide,
            polygonOffset: true,
            polygonOffsetFactor: -RENDER_ORDER_FRONT,
            polygonOffsetUnits: -RENDER_ORDER_FRONT,
          })
        }
      />
      <Line
        points={Array.from(crosshairGeometryX.getAttribute("position").array)}
        position={bottomElementsPosition}
        rotation={bottomElementsRotation}
        renderOrder={RENDER_ORDER_BACK}
        color={CROSSHAIR_LINE_COLOR}
        polygonOffset
        polygonOffsetFactor={-RENDER_ORDER_BACK}
        polygonOffsetUnits={-RENDER_ORDER_BACK}
        lineWidth={INSPECTION_WINDOW_OUTLINE_WIDTH}
      />
      <Line
        points={Array.from(crosshairGeometryY.getAttribute("position").array)}
        position={bottomElementsPosition}
        rotation={bottomElementsRotation}
        renderOrder={RENDER_ORDER_BACK}
        color={CROSSHAIR_LINE_COLOR}
        polygonOffset
        polygonOffsetFactor={-RENDER_ORDER_BACK}
        polygonOffsetUnits={-RENDER_ORDER_BACK}
        lineWidth={INSPECTION_WINDOW_OUTLINE_WIDTH}
      />
    </>
  );
};
