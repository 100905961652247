import {
  useObjects,
  useViewerContext,
  waitForObjectLoad,
} from "@promaton/scan-viewer";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAsync } from "react-use";
import type { AsyncState } from "react-use/lib/useAsyncFn";
import { CylinderGeometry, Mesh } from "three";

import { Step } from "../enums/step";
import { useMeshBVH } from "../hooks/useMeshBVH";
import { useRecenterByStep } from "../hooks/useRecenterByStep";
import { useAppState } from "../store/AppState";
import {
  detectIntersections,
  getDefaultCylinderArgs,
  getPositionCorrectionForInspectionWindow,
  resetHeatmap,
} from "../utils/3d";
import { requestGuideParamsFromAPI } from "../utils/api";
import { captureAndReportAxiosError } from "../utils/error";
import { InspectionWindow } from "./InspectionWindow";

interface InspectionWindowsProps {
  taskId: string;
}

export const InspectionWindows = ({ taskId }: InspectionWindowsProps) => {
  useRecenterByStep();

  const [intersectionsComputed, setIntersectionsComputed] = useState(false);

  const guideParams = useAppState((s) => s.guideParams);
  const setGuideParams = useAppState((s) => s.setGuideParams);
  const currentStep = useAppState((s) => s.currentStep);
  const setIsViewerLoading = useAppState((s) => s.setViewerIsLoading);
  const setIsNextButtonEnabled = useAppState((s) => s.setIsNextButtonEnabled);
  const setCurrentLoadingProgress = useAppState(
    (s) => s.setCurrentLoadingProgress
  );
  const errors = useAppState((s) => s.errors);
  const setErrors = useAppState((s) => s.setErrors);
  const apiKey = useAppState((s) => s.apiKey);

  const getMeshByObjectId = useViewerContext((s) => s.getMeshByObjectId);
  const objects = useObjects((s) => s.objects);
  const isInspectionWindowsEnabled = useAppState(
    (s) => s.isInspectionWindowsEnabled
  );

  const guideMesh: AsyncState<Mesh | null> = useAsync(async () => {
    const guideId = Object.entries(objects).find(([id, _]) =>
      id.match(/guide/i)
    )?.[0];

    let mesh: Mesh | null = null;
    if (guideId) {
      await waitForObjectLoad(guideId);
      mesh = getMeshByObjectId(guideId);
    }

    return mesh;
  }, [objects, getMeshByObjectId, currentStep]);

  useEffect(() => {
    setIntersectionsComputed(false);
  }, []);

  useEffect(() => {
    const loadGuideMesh = async () => {};

    loadGuideMesh();
  }, [objects, getMeshByObjectId, currentStep]);

  const intersectionClonedMesh = useMemo(
    () => (guideMesh.value ? guideMesh.value.clone() : null),
    [guideMesh.value]
  );

  useEffect(() => {
    if (!intersectionsComputed) {
      runIntersectionDetection();
    }
  }, [intersectionsComputed, currentStep]);

  useEffect(() => {
    if (
      currentStep !== Step.REVIEW_INSPECTION_WINDOWS &&
      guideMesh.value &&
      intersectionsComputed
    ) {
      resetHeatmap(guideMesh.value, intersectionClonedMesh);
    }

    if (
      currentStep === Step.REVIEW_INSPECTION_WINDOWS &&
      guideParams &&
      guideParams.contact_points_params.length > 0
    ) {
      setIsNextButtonEnabled(true);
    }
  }, [guideParams, currentStep, intersectionsComputed]);

  const meshBVH = useMeshBVH(intersectionClonedMesh);

  const runIntersectionDetection = useCallback(() => {
    if (
      currentStep !== Step.REVIEW_INSPECTION_WINDOWS ||
      !intersectionClonedMesh ||
      !meshBVH?.value ||
      !guideParams
    )
      return;

    const inspectionWindows = guideParams.inspection_windows_params.map(
      (point) => {
        const cylinderArgs = getDefaultCylinderArgs(point);
        const position = getPositionCorrectionForInspectionWindow(point);
        return new CylinderGeometry(
          cylinderArgs.radiusTop,
          cylinderArgs.radiusBottom,
          cylinderArgs.height,
          cylinderArgs.radialSegments,
          cylinderArgs.heightSegments
        ).translate(position.x, position.y, position.z);
      }
    );

    inspectionWindows.forEach((cylinder) => {
      const cylinderMesh = new Mesh(cylinder);

      meshBVH.value &&
        detectIntersections(
          meshBVH.value,
          cylinderMesh,
          intersectionClonedMesh
        );
    });

    setIntersectionsComputed(true);
  }, [meshBVH.value, guideParams, intersectionClonedMesh, currentStep]);

  useAsync(async () => {
    try {
      if (
        !taskId ||
        guideParams ||
        currentStep !== Step.REVIEW_INSPECTION_WINDOWS
      )
        return;

      setIsViewerLoading(true);
      setCurrentLoadingProgress(() => 0);

      const data = await requestGuideParamsFromAPI(
        taskId,
        isInspectionWindowsEnabled,
        apiKey,
        setCurrentLoadingProgress
      );

      setGuideParams(data);
      setIsNextButtonEnabled(true);
      setIsViewerLoading(false);
      return data;
    } catch (err) {
      await captureAndReportAxiosError(
        err,
        errors,
        setErrors,
        "Error generating inspection windows"
      );
    }
  }, [taskId, currentStep, apiKey]);

  if (!guideParams || currentStep !== Step.REVIEW_INSPECTION_WINDOWS)
    return null;

  return (
    <group name="inspection_windows">
      {guideParams.inspection_windows_params.map((point, index) => (
        <InspectionWindow
          key={point.id}
          point={point}
          index={index}
          currentStep={currentStep}
        />
      ))}
    </group>
  );
};
