import { guide } from "@promaton/api-client";
import type { ViewerObject } from "@promaton/scan-viewer";
import {
  getCenterOfObjectById,
  useObjects,
  useViewerContext,
  waitForObjectLoad,
} from "@promaton/scan-viewer";
import { useCallback, useState } from "react";
import { useAsync } from "react-use";
import { Vector3 } from "three";

import { Step } from "../enums/step";
import { useAppState } from "../store/AppState";
import {
  analyzeNormalsAndDetermineDirection,
  createCustomArrow,
  generate3DObjectSTLURL,
} from "../utils/3d";
import { getAPIOptions } from "../utils/api";
import { generateRandomGuideColorWithSeed } from "../utils/color";
import { captureAndReportAxiosError } from "../utils/error";
import { inferJawTypeFromFDI } from "../utils/fdi";
import { unpackZip } from "../utils/file";
import { extractSleeveAndFDIInformation } from "../utils/sleeve";

interface GuideDesignJSONResults {
  DrillGuideDesign: {
    SleeveMounts: { SleeveMount: SleeveMountRaw[] };
    InsertDirection_X: number;
    InsertDirection_Y: number;
    InsertDirection_Z: number;
    HorizontalDirection_X: number;
    HorizontalDirection_Y: number;
    HorizontalDirection_Z: number;
  };
}

const INSERTION_DIRECTION_OFFSET = 25;
const INSERTION_DIRECTION_ARROW_LENGTH = 15;
const INSERTION_DIRECTION_ARROW_WIDTH = 0.5;

export const useLoadScan = (taskId?: string) => {
  const getMeshByObjectId = useViewerContext((s) => s.getMeshByObjectId);
  const setObject = useObjects((s) => s.setObject);
  const setIsScanLoaded = useAppState((s) => s.setIsScanLoaded);
  const currentStep = useAppState((s) => s.currentStep);
  const setIsViewerLoading = useAppState((s) => s.setViewerIsLoading);
  const setIsNextButtonEnabled = useAppState((s) => s.setIsNextButtonEnabled);
  const setSleeveParameters = useAppState((s) => s.setSleeveParameters);
  const setImplantFDI = useAppState((s) => s.setImplantFDI);
  const setJawType = useAppState((s) => s.setJawType);
  const setInferredDirection = useAppState((s) => s.setInferredDirection);
  const setInferredVerticalDirection = useAppState(
    (s) => s.setInferredVerticalDirection
  );
  const setCurrentLoadingProgress = useAppState(
    (s) => s.setCurrentLoadingProgress
  );
  const errors = useAppState((s) => s.errors);
  const setErrors = useAppState((s) => s.setErrors);
  const apiKey = useAppState((s) => s.apiKey);

  const [isRetrievingScan, setIsRetrievingScan] = useState(false);

  const loadImplant = useCallback(
    (results: File[]) => {
      const implantStl = results.find((result) => /implant/i.exec(result.name));

      const implantObject: ViewerObject | undefined = implantStl && {
        url: URL.createObjectURL(implantStl),
        group: "Implant",
        objectType: "stl",
        clipToPlanes: true,
        color: "slategrey",
        metalness: 0.5,
        roughness: 0.2,
      };

      implantObject && setObject(implantStl.name, implantObject);
    },
    [setObject]
  );

  const loadSleeve = useCallback(
    (results: File[]) => {
      const sleeveStl = results.find((result) => /sleeve/i.exec(result.name));

      const sleeveObject: ViewerObject | undefined = sleeveStl && {
        url: URL.createObjectURL(sleeveStl),
        group: "Sleeves",
        objectType: "stl",
        clipToPlanes: true,
        color: "slategrey",
      };

      sleeveObject && setObject("sleeve", sleeveObject);
    },
    [setObject]
  );

  const loadScan = useCallback(
    (results: File[]) => {
      const scanStl = results.find(
        (result) => /upper/i.exec(result.name) || /lower/i.exec(result.name)
      );

      if (!scanStl) return;

      const scanObject: ViewerObject = scanStl && {
        url: URL.createObjectURL(scanStl),
        group: "Scans",
        objectType: "stl",
        renderOrder: 1,
        clipToPlanes: true,
      };

      const id = `${scanStl.name}-${Date.now()}`;

      setObject(id, scanObject);
      setIsScanLoaded(true);

      return id;
    },
    [setObject]
  );

  const loadGuideParams = useCallback(
    (guideDesignJson: GuideDesignJSONResults) => {
      const sleeveAndFDIInformation = extractSleeveAndFDIInformation(
        guideDesignJson.DrillGuideDesign
      );

      setSleeveParameters(sleeveAndFDIInformation.sleeveParameters);
      setImplantFDI(sleeveAndFDIInformation.implant_fdi);
    },
    [setSleeveParameters, setImplantFDI]
  );

  const loadInsertionDirection = useCallback(
    async (guideDesignJson: GuideDesignJSONResults, scanName: string) => {
      const mesh = getMeshByObjectId(scanName);

      const inferredVerticalDirection = analyzeNormalsAndDetermineDirection(
        mesh.geometry
      );

      const horizontalDirectionXNormalized =
        guideDesignJson.DrillGuideDesign.HorizontalDirection_X === 0
          ? -1
          : guideDesignJson.DrillGuideDesign.HorizontalDirection_X;

      const inferredDirection =
        inferredVerticalDirection < 0 ? 1 : horizontalDirectionXNormalized;

      setInferredDirection(inferredDirection);
      setInferredVerticalDirection(inferredVerticalDirection);

      const insertionDirection = new Vector3(
        guideDesignJson.DrillGuideDesign.InsertDirection_X,
        guideDesignJson.DrillGuideDesign.InsertDirection_Z,
        guideDesignJson.DrillGuideDesign.InsertDirection_Y
      );

      insertionDirection.normalize();

      const scanCenter = scanName && getCenterOfObjectById(scanName);

      const origin =
        scanCenter &&
        new Vector3(
          scanCenter.center.x,
          scanCenter.center.y +
            INSERTION_DIRECTION_OFFSET * inferredVerticalDirection,
          scanCenter.center.z
        );

      const arrowHelper =
        origin &&
        createCustomArrow(
          insertionDirection,
          inferredVerticalDirection,
          origin,
          INSERTION_DIRECTION_ARROW_WIDTH,
          INSERTION_DIRECTION_ARROW_LENGTH
        );

      const arrowStlUrl =
        arrowHelper && (await generate3DObjectSTLURL(arrowHelper));
      if (arrowStlUrl) {
        const arrowObject: ViewerObject | undefined = {
          url: arrowStlUrl,
          group: "Arrows",
          objectType: "stl",
          clipToPlanes: true,
          flatShading: true,
          roughness: 0.8,
          metalness: 0.2,
          color: generateRandomGuideColorWithSeed(taskId),
        };

        arrowObject && setObject("insertionDirection2", arrowObject);
      }
    },
    [setObject]
  );

  const compressedFolder = useAsync(async () => {
    if (
      !apiKey ||
      isRetrievingScan ||
      !taskId ||
      currentStep !== Step.REVIEW_VIEW_DIRECTION
    )
      return;

    try {
      setIsRetrievingScan(true);
      setIsViewerLoading(true);

      const options = getAPIOptions(setCurrentLoadingProgress, apiKey, {
        responseType: "arraybuffer",
      });
      const response =
        await guide.getCompressedFolder.getCompressedFolderGetCompressedFolderTaskIdGet(
          taskId,
          options
        );

      const arrayBuffer: ArrayBuffer = response?.data as ArrayBuffer;
      const file = new Blob([new Uint8Array(arrayBuffer)], {
        type: "application/octet-stream",
      });

      const results = await unpackZip(file);

      const guideDesignJsonFile = results.find((result) =>
        /GuideDesign.json/i.exec(result.name)
      );

      if (guideDesignJsonFile) {
        const guideDesignJson: GuideDesignJSONResults = await new Promise(
          (resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (event) {
              const arrayBuffer = event?.target?.result as ArrayBuffer;
              const text = new TextDecoder().decode(arrayBuffer);
              text && resolve(JSON.parse(text));
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(guideDesignJsonFile);
          }
        );

        loadGuideParams(guideDesignJson);
        const fdi =
          guideDesignJson.DrillGuideDesign.SleeveMounts.SleeveMount[0]
            .ToothPosition;

        const scanName = loadScan(results);
        const jawType = inferJawTypeFromFDI(fdi);

        setJawType(jawType);
        loadImplant(results);
        loadSleeve(results);

        scanName && (await waitForObjectLoad(scanName, 10000));
        scanName && loadInsertionDirection(guideDesignJson, scanName);
      }

      setCurrentLoadingProgress(() => 0);
      setIsNextButtonEnabled(true);
      setIsViewerLoading(false);

      return results;
    } catch (err: unknown) {
      await captureAndReportAxiosError(
        err,
        errors,
        setErrors,
        "Error loading scan"
      );
    }
  }, [taskId, currentStep, isRetrievingScan, apiKey]);

  return compressedFolder;
};
