import type { AxiosError } from "axios";

export const extractErrorFromResponse = (err: unknown) => {
  const error = err as AxiosError;
  let message = error?.response?.data;

  if (message instanceof ArrayBuffer) {
    const decoder = new TextDecoder("utf-8");
    message = decoder.decode(message);
  }

  try {
    const jsonObj = typeof message === "string" ? JSON.parse(message) : message;
    return jsonObj.detail.split("|")[1];
  } catch (parseError) {
    return `${parseError.message}`;
  }
};

export const captureAndReportAxiosError = async (
  err: unknown,
  errors: string[],
  setErrors: (errors: string[]) => void,
  message: string
) => {
  const error = err as AxiosError;
  const errorDetails = extractErrorFromResponse(error);

  const errorMessage =
    error?.response?.status === 401
      ? `Unauthorised access. Please contact the administrator.`
      : `${message} - ${errorDetails}`;

  setErrors([...errors, errorMessage]);

  const Sentry = await import("@sentry/react");
  Sentry.captureException(error);
};
