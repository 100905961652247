import {
  CircularProgress,
  LinearProgress,
  Stack,
  styled,
  Typography,
} from "@mui/material";

import { Step } from "../enums/step";
import { useAppState } from "../store/AppState";

interface OverlayLoaderProps {
  taskId: string | undefined;
}

export const OverlayLoader = ({ taskId }: OverlayLoaderProps) => {
  const viewerIsLoading = useAppState((state) => state.viewerIsLoading);
  const currentStep = useAppState((s) => s.currentStep);
  const errors = useAppState((s) => s.errors);
  const currentLoadingProgress = useAppState((s) => s.currentLoadingProgress);

  if ((!viewerIsLoading && taskId) || currentStep === Step.SET_GUIDE_PARAMETERS)
    return null;

  return (
    <Overlay>
      {errors.length > 0 ? (
        <Wrapper>
          {errors.map((error) => (
            <Typography
              key={error}
              variant="h6"
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.7)",
                color: "salmon",
                padding: 4,
              }}
            >
              {error}
            </Typography>
          ))}
        </Wrapper>
      ) : (
        <Wrapper>
          <Typography variant="h6" sx={{ color: "white" }}>
            {!taskId ? (
              <Stack width="100%" justifyContent="center" alignItems="center">
                <Typography variant="h4">
                  No task-id provided, please provide a task-id
                </Typography>
              </Stack>
            ) : null}
            {currentLoadingProgress === 0 && viewerIsLoading ? (
              <Stack justifyContent="center" alignItems="center" gap={2}>
                <CircularProgress color="inherit" />
                <Typography variant="body2">Warming up...</Typography>
              </Stack>
            ) : null}
            {currentLoadingProgress !== 0 &&
            taskId &&
            currentStep === Step.REVIEW_VIEW_DIRECTION
              ? `Loading scan... ${Math.round(currentLoadingProgress)}%`
              : null}
            {currentLoadingProgress !== 0 &&
            taskId &&
            currentStep === Step.REVIEW_CONTACT_POINTS
              ? `Computing contact points... ${Math.round(currentLoadingProgress)}%`
              : null}
            {currentLoadingProgress !== 0 &&
            taskId &&
            currentStep === Step.GENERATE_GUIDE
              ? `Generating guide... ${Math.round(currentLoadingProgress)}%`
              : null}
            {currentLoadingProgress !== 0 && taskId && (
              <LinearProgress
                sx={{ marginTop: 2 }}
                variant="determinate"
                value={currentLoadingProgress}
              />
            )}
          </Typography>
        </Wrapper>
      )}
    </Overlay>
  );
};

const Overlay = styled("div")(() => ({
  position: "absolute",
  zIndex: 9999,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0,0,0,0.5)",
  transition: "all 0.2s ease-in-out",
}));

const Wrapper = styled(Stack)(() => ({
  margin: "auto",
  width: "100%",
  height: "100%",
  gap: 2,
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
}));
