import {
  useLoadingState,
  useObjects,
  useViewId,
  useViews,
} from "@promaton/scan-viewer";
import { useEffect } from "react";

import { JawType, recenterMap } from "../enums/jaw";
import { Step } from "../enums/step";
import { useAppState } from "../store/AppState";

export const useRecenterByStep = () => {
  const jawType = useAppState((s) => s.jawType);
  const inferredDirection = useAppState((s) => s.inferredDirection);
  const inferredVerticalDirection = useAppState(
    (s) => s.inferredVerticalDirection
  );
  const currentStep = useAppState((s) => s.currentStep);
  const firstLoadComplete = useLoadingState((s) => s.firstLoadComplete);
  const objects = useObjects((s) => s.objects);
  const viewID = useViewId();
  const recenter = useViews((s) => s.views[viewID]?.recenter);

  useEffect(() => {
    if (
      !firstLoadComplete ||
      !jawType ||
      currentStep === Step.SET_GUIDE_PARAMETERS
    )
      return;

    if (
      currentStep === Step.REVIEW_VIEW_DIRECTION ||
      currentStep === Step.APPROVE_GUIDE
    ) {
      const zRecenterValue =
        jawType === JawType.LOWER ? inferredDirection : -inferredDirection;

      recenter?.([0, 0, zRecenterValue]);
      return;
    }

    const yRecenterValue = recenterMap[jawType](inferredVerticalDirection);

    recenter?.([0, yRecenterValue, 0]);
  }, [
    currentStep,
    recenter,
    firstLoadComplete,
    objects,
    jawType,
    inferredDirection,
  ]);
};
