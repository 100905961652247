import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import type { JawType } from "../enums/jaw";
import { Step } from "../enums/step";

const getTaskIdFromURL = () => {
  const pathname = window.location.pathname;
  return pathname.split("/").pop() || "";
};

export interface AppState {
  currentStep: Step;
  setCurrentStep: (currentStep: Step) => void;
  isScanLoaded: boolean;
  setIsScanLoaded: (isScanLoaded: boolean) => void;
  viewerIsLoading: boolean;
  setViewerIsLoading: (viewerIsLoading: boolean) => void;
  guideParams: GuideParams | null;
  setGuideParams: (contactPoints: GuideParams) => void;
  isNextButtonEnabled: boolean;
  setIsNextButtonEnabled: (isNextButtonEnabled: boolean) => void;
  isInspectionWindowsEnabled: boolean;
  setIsInspectionWindowsEnabled: (isInspectionWindowsEnabled: boolean) => void;
  guideParameters: GuideProperties;
  setGuideParameters: (guideParameters: GuideProperties) => void;
  sleeveParameters: SleeveParams | null;
  setSleeveParameters: (sleeveParameters: SleeveParams) => void;
  implantFDI: number;
  setImplantFDI: (implantFDI: number) => void;
  errors: string[];
  setErrors: (errors: string[]) => void;
  currentLoadingProgress: number;
  setCurrentLoadingProgress: (
    updateFunction: (currentState: number) => number
  ) => void;
  jawType: JawType | null;
  setJawType: (jawType: JawType) => void;
  inferredDirection: number;
  setInferredDirection: (inferredDirection: number) => void;
  inferredVerticalDirection: number;
  setInferredVerticalDirection: (inferredVerticalDirection: number) => void;
  guideColor: string | null;
  setGuideColor: (guideColor: string) => void;
  apiKey: string | undefined;
  setApiKey: (apiKey: string) => void;
}

export const useAppState = create<AppState>()(
  persist(
    (set) => ({
      currentStep: Step.REVIEW_VIEW_DIRECTION,
      viewerIsLoading: false,
      isScanLoaded: false,
      setIsScanLoaded: (isScanLoaded) => set({ isScanLoaded }),
      setCurrentStep: (currentStep) => set({ currentStep }),
      setViewerIsLoading: (viewerIsLoading) => set({ viewerIsLoading }),
      guideParams: null,
      setGuideParams: (contactPoints) => set({ guideParams: contactPoints }),
      isNextButtonEnabled: false,
      setIsNextButtonEnabled: (isNextButtonEnabled) =>
        set({ isNextButtonEnabled }),
      isInspectionWindowsEnabled: false,
      setIsInspectionWindowsEnabled: (isInspectionWindowsEnabled) =>
        set({ isInspectionWindowsEnabled }),
      guideParameters: {
        wallThickness: 2.5,
        guideOffset: 0.15,
      },
      setGuideParameters: (guideParameters) => set({ guideParameters }),
      sleeveParameters: null,
      setSleeveParameters: (sleeveParameters) => set({ sleeveParameters }),
      implantFDI: 0,
      setImplantFDI: (implantFDI) => set({ implantFDI }),
      errors: [],
      setErrors: (errors) => set({ errors }),
      currentLoadingProgress: 0,
      setCurrentLoadingProgress: (updateFunction) =>
        set((state) => ({
          currentLoadingProgress: updateFunction(state.currentLoadingProgress),
        })),
      jawType: null,
      setJawType: (jawType) => set({ jawType }),
      inferredDirection: 1,
      setInferredDirection: (inferredDirection) => set({ inferredDirection }),
      inferredVerticalDirection: 1,
      setInferredVerticalDirection: (inferredVerticalDirection) =>
        set({ inferredVerticalDirection }),
      guideColor: null,
      setGuideColor: (guideColor) => set({ guideColor }),
      apiKey: undefined,
      setApiKey: (apiKey) => set({ apiKey }),
    }),
    {
      name: `guide-storage_${getTaskIdFromURL()}`,
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        guideParameters: state.guideParameters,
        sleeveParameters: state.sleeveParameters,
      }),
    }
  )
);
