interface ExtendedWindow extends Window {
  chrome?: {
    webview?: {
      postMessage: (message: unknown) => void;
    };
  };
}

export const communicateSuccessToDesktopApp = () => {
  (window as ExtendedWindow)?.chrome?.webview?.postMessage({
    guideDesign: { status: "success" },
  });
};
