export const extractSleeveAndFDIInformation = (drillGuideDesign: {
  SleeveMounts: { SleeveMount: SleeveMountRaw[] };
}) => {
  const sleeveMounts: SleeveMountRaw[] =
    drillGuideDesign?.SleeveMounts.SleeveMount;
  const sleeveMount: SleeveMountRaw = sleeveMounts?.[0];

  return {
    sleeveParameters: {
      diameter: sleeveMount?.Diameter,
      height: sleeveMount?.Height,
      orientation_x: sleeveMount?.OrientationX,
      orientation_y: sleeveMount?.OrientationY,
      orientation_z: sleeveMount?.OrientationZ,
      position_x: sleeveMount?.PositionX,
      position_y: sleeveMount?.PositionY,
      position_z: sleeveMount?.PositionZ,
    },
    implant_fdi: sleeveMount?.ToothPosition,
  };
};
