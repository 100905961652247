import * as createGuide from "./generated/api/create-guide";
import * as createGuideParams from "./generated/api/create-guide-params";
import * as guideDefault from "./generated/api/default";
import * as getCompressedFolder from "./generated/api/get-compressed-folder";
import * as health from "./generated/api/health";

export * from "./generated/model";

const guide = {
  createGuide,
  guideDefault,
  getCompressedFolder,
  health,
  createGuideParams,
};

export { guide };
