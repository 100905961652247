/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * Guide Design
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  HTTPValidationError
} from '../model'




/**
 * @summary Endpoint responsible for downloading the compressed folder (zip file) pointed by the task_id
 */
export const getCompressedFolderGetCompressedFolderTaskIdGet = (
    taskId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    
    return axios.get(
      `/get_compressed_folder/${taskId}`,options
    );
  }


export const getGetCompressedFolderGetCompressedFolderTaskIdGetQueryKey = (taskId: string,) => {
    return [`/get_compressed_folder/${taskId}`] as const;
    }

    
export const getGetCompressedFolderGetCompressedFolderTaskIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getCompressedFolderGetCompressedFolderTaskIdGet>>, TError = AxiosError<HTTPValidationError>>(taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompressedFolderGetCompressedFolderTaskIdGet>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCompressedFolderGetCompressedFolderTaskIdGetQueryKey(taskId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompressedFolderGetCompressedFolderTaskIdGet>>> = ({ signal }) => getCompressedFolderGetCompressedFolderTaskIdGet(taskId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(taskId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCompressedFolderGetCompressedFolderTaskIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCompressedFolderGetCompressedFolderTaskIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getCompressedFolderGetCompressedFolderTaskIdGet>>>
export type GetCompressedFolderGetCompressedFolderTaskIdGetQueryError = AxiosError<HTTPValidationError>

/**
 * @summary Endpoint responsible for downloading the compressed folder (zip file) pointed by the task_id
 */
export const useGetCompressedFolderGetCompressedFolderTaskIdGet = <TData = Awaited<ReturnType<typeof getCompressedFolderGetCompressedFolderTaskIdGet>>, TError = AxiosError<HTTPValidationError>>(
 taskId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCompressedFolderGetCompressedFolderTaskIdGet>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCompressedFolderGetCompressedFolderTaskIdGetQueryOptions(taskId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



