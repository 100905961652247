import type { ViewerObject, ViewerObjectMap } from "@promaton/scan-viewer";
import { FileType, inferTypeFromUrl } from "@promaton/scan-viewer";
import type { Entry } from "@zip.js/zip.js";

export const getZipEntries = async (data: Blob, password?: string) => {
  const { BlobReader, ZipReader } = await import("@zip.js/zip.js");
  const blobReader = new BlobReader(data);
  const zip = new ZipReader(blobReader, { password });
  return zip.getEntries();
};

export const unpackZipEntry = async (entry: Entry) => {
  const fileName = entry.filename;

  if (
    fileName?.startsWith(".") ||
    fileName?.startsWith("_") ||
    !fileName ||
    !entry.getData ||
    entry.directory
  )
    return;

  const blobWriter = new TransformStream();
  const blob = new Response(blobWriter.readable).blob();

  await entry.getData(blobWriter.writable);
  return new File([await blob], fileName);
};

export const unpackZip = async (data: Blob, password?: string) => {
  const entries = await getZipEntries(data, password);
  return (await Promise.all(Object.values(entries).map(unpackZipEntry))).filter(
    Boolean
  );
};

export const unpackZipFor3DViewer = async (
  data: Blob,
  password?: string
): Promise<ViewerObjectMap> => {
  try {
    const files = await unpackZip(data, password);
    const objects = files
      .map((file) => create3DViewerObjectFromFile(file))
      .filter(Boolean);

    return Object.fromEntries(objects);
  } catch (e) {
    throw new Error("Could not unpack zip file");
  }
};

export const create3DViewerObjectFromFile = (
  file: File
): [string, ViewerObject] | undefined => {
  const name =
    file.name.split(".")[1] !== "json"
      ? `${file.name.split(".")[0]}-${file.name.split(".")[1]}`
      : file.name.split(".")[0];

  const objectType = inferTypeFromUrl(file.name);

  if (objectType === undefined) return;

  const url = URL.createObjectURL(file);
  const data: ViewerObject = {
    url,
    objectType,
    clipToPlanes: true,
    group: "scans",
  };

  if (objectType === FileType.PLY) {
    data.metalness = 0;
    data.roughness = 0.25;
  }

  return [name.toUpperCase(), data];
};
