import { wrap } from "comlink";
import { useAsync } from "react-use";
import type { Mesh } from "three";
import { MeshBVH } from "three-mesh-bvh";

import type { MeshBVHWorkerType } from "../workers/createBVH";
import createBVH from "../workers/createBVH?worker&inline";

const bvhWorker = wrap<MeshBVHWorkerType>(new createBVH());

export const useMeshBVH = (mesh: Mesh | null) => {
  return useAsync(async () => {
    if (!mesh) return;
    mesh.geometry.computeVertexNormals();

    const bvh = await bvhWorker(mesh.geometry.attributes, mesh.geometry.index);
    mesh.geometry.boundsTree = MeshBVH.deserialize(bvh, mesh.geometry);

    return mesh;
  }, [mesh]);
};
